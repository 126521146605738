import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { FileUploadRequest } from './interfaces/file-upload-request';

@Injectable({
  providedIn: 'root'
})
export class FileService extends RestGeneric<any, any, any> {
  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    super(http, authFactoryService, 'file', restGenericUtilService);
  }

  upload(requestType: FileUploadRequest): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/${this.endpointName}/${this.schoolUUid}/csv`, requestType);
  }

  getPrefix(){
    return this.http.get<any>(`${this.baseApiUrl}/${this.schoolUUid}/getPrefix`);
  }
}
