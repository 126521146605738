<ng-container *ngIf="form">
    <app-select
      [form]="form"
      label="{{ 'SOURCE' | translate }}"
      [className]="className"
      [controlName]="controlName"
      [items]="[
        { id: true, name: 'MANUAL' | translate },
        { id: false, name: 'FILE' | translate },
      ]"
    >
    </app-select>
  </ng-container>
  