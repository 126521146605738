<div class="{{ className }}">
    <label *ngIf="!hideLabel">{{ 'STUDENT' | translate }}</label>
    <ng-container *ngIf="form && controlName">
      <app-auto-complete
        (selectedItemChange)="onSelectChangeStudent($event)"
        (searchChange)="onSearchChangeStudent($event)"
        [itemsData]="students"
        [selectedItem]="form.get(controlName)?.value"
        [minTermLength]="1"
      ></app-auto-complete>
    </ng-container>
  </div>
  