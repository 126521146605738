export enum AppRoute {
  Dashboard = 'dashboard',
  Roster = 'group',
  Activity = 'activity',
  Flex = 'flex',
  Schedule = 'schedule',
  Cancellation = 'cancellation',
  CutList = 'cut-list',
  FullAttendance = 'full-attendance',
  ExcludedStudents = 'excluded-students',
  AttendanceActivity = 'attendance-activity',
  RoomUse = 'room-use',
  StudentAttendance = 'student-attendance',
  StudentSearch = 'student-search',
  StudentList = 'student-list',
  RestrictedStudents = 'restricted-students',
  ABPolarity = 'ab-polarity',
  TeacherList = 'teacher-list',
  TeacherAttendance = 'teacher-attendance',
  Substitute = 'substitute',
  ActivityTypes = 'activity-types',
  Priorities = 'priorities',
  Room = 'room',
  User = 'user',
  Announcement = 'announcement',
  Notifications = 'notifications',
  Log = 'log',
  SystemSettings = 'system-settings',
  SftpFiles = 'sftp-files',
  Login = 'login',
  GoogleLogin = 'glogin',
  ClasslinkLogin = 'classlinklogin',
  EdlinkLogin = 'edlinklogin',
  CleverLogin = 'clogin',
  MicrosoftLogin = 'ologin',
  ResetPassword = 'reset-password',
  ResetPasswordInitiate = 'reset-password-initiate',
  Directory = 'directory',
  TrainingVideos = 'training-videos',
  Documentation = 'documentation',
  MyProfile = 'my-profile',
  AuthCallBack = 'authcallback',
  AuthCallBackMobile = 'authcallback-mobile',
  NoAccess = 'no-access',
  AuthExternalCallBack = 'authexternalcallback',
  AttendanceReminder = 'attendance-reminder',
  AutoSyncGroups = 'group-auto-sync',
  QRCode = 'qrcode',
  DefaultActivityLists = 'default-activity-lists',
  // general routes segments
  Add = 'add',
  Preview = 'preview',
  Edit = 'edit',
  // Custom route segments
  ActivityList = 'activity-list',
  AddGroupByGroups = 'add-group-by-groups',
  // hidden activity registration export premium feature
  ActivityRegistrationsExport = 'activity-registration-export',
  VerifyEmailAddress = 'verify-email',
  PreferenceRoster="preference-roster"
}
