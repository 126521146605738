<div class="{{ className }}">
    <label *ngIf="!hideLabel">{{ label | translate }}</label>
    <ng-container *ngIf="form && controlName">
      <app-auto-complete
        (selectedItemChange)="onSelectChangeTeacher($event)"
        (searchChange)="onSearchChangeTeacher($event)"
        [itemsData]="owners"
        [selectedItem]="form.get(controlName)?.value"
        [minTermLength]="1"
        [disabled]="disabled"
      ></app-auto-complete>
    </ng-container>
  </div>
  