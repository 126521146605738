import { UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AppConfig } from 'src/app/configs/app.config';
import { AutoCompleteItem } from '../../interfaces/autocomplete-item';
import { User } from 'src/app/core/services/http/user/interfaces/user';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { UserService } from 'src/app/core/services/http/user/user.service';
import { ClassInputType } from 'src/app/shared/shared-extensions/types/class-input-type';
import { UserAdapterService } from 'src/app/core/services/http/user/adapters/user-adapter.service';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { ObservableWrapperService } from 'src/app/core/services/observable-wrapper/observable-wrapper.service';
import { Authority } from 'src/app/constants/authority';

@Component({
  selector: 'app-auto-complete-student',
  templateUrl: './auto-complete-student.component.html',
  styleUrls: ['./auto-complete-student.component.scss']
})
export class AutoCompleteStudentComponent extends DestroyNotifierComponent{
  @Input() controlName = 'student';
  @Input() hideLabel = false;
  @Input() form: UntypedFormGroup | undefined;
  @Input() className: ClassInputType = 'app-input';
  @Input() extractMemberShipUUID = false;

  @Output() selectChange = new EventEmitter();

  students: AutoCompleteItem[] = [];
  paging = new Paging({ pageSize: AppConfig.AutoComplete.pageSize });
  
  constructor(
    private userService: UserService,
    private userAdapterService: UserAdapterService,
    private observableWrapperService: ObservableWrapperService
  ) {
    super();
  }

  onSelectChangeStudent(student: AutoCompleteItem): void {
    if (this.controlName) {
      this.form?.get(this.controlName)?.setValue(student);
      this.selectChange.emit(student);
      this.form?.get(this.controlName)?.markAsDirty();
    }
  }

  onSearchChangeStudent(filter: string): void {
    const requestFilters: Record<string, any> = { filter, role: Authority.Student, status: Status.Active };

    this.observableWrapperService.unsubscribeWrapper<User[]>(
      this.userService.getAll(this.paging, requestFilters),
      this.destroyNotify,
      students => {
        this.students = this.userAdapterService.userToAutoCompleteItem(students);
      }
    );
  }
} 
