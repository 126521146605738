import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/configs/app.config';
import { AuthFactoryService } from '../auth/auth-factory.service';
import { ExtendedDate } from './classes/extended-date';

@Injectable({
  providedIn: 'root'
})
export class DateManagerService {
  constructor(private authFactoryService: AuthFactoryService) { }

  dateToString(selectedDate: ExtendedDate | string | number): string {
    const date = new ExtendedDate(selectedDate);

    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
  }

  getTimeByUTCShift(shiftSec: number): ExtendedDate {
    const date = new ExtendedDate();
    date.resetHours();
    date.setSeconds(shiftSec);

    return date;
  }

  getDateTimeBy(
    cutoffDays: number,
    cutoffTime: number,
    cutoffCountWeekends: boolean,
    calendarDate: ExtendedDate
  ): Date {
    let cutoffDate = new ExtendedDate(calendarDate);

    if (cutoffDays > 0) {
      if (cutoffCountWeekends) {
        cutoffDate.setDate(cutoffDate.getDate() - cutoffDays);
      } else {
        cutoffDate = this.getDateByWorkDayAndOffset(cutoffDate, cutoffDays, -1);
      }
    }

    cutoffDate.resetHours();
    cutoffDate.setSeconds(cutoffTime);

    return cutoffDate;
  }

  getDateBySchoolUtcOffset(date = new ExtendedDate(), isDST = this.isDSTTime(), addCurrentDateTimezoneOffset = false): ExtendedDate {
    if (date.isCalculatedToSpecificTimeZone) {
      return date;
    }

    let schoolUtcOffsetInSec = this.authFactoryService.service.getSchoolUtcOffsetInSec();

    if (schoolUtcOffsetInSec === undefined) {
      throw new Error('schoolUtcOffsetInSec can`t be null');
    }

    if (isDST) {
      schoolUtcOffsetInSec += 3600;
    }

    if (addCurrentDateTimezoneOffset) {
      const currentTimeZoneOffsetInSec = date.getTimezoneOffset() * 60;
      schoolUtcOffsetInSec += currentTimeZoneOffsetInSec;
    }

    const extendedDate = new ExtendedDate(date.getTime() + schoolUtcOffsetInSec * 1000);
    extendedDate.isCalculatedToSpecificTimeZone = true;

    return extendedDate;
  }

  getSecondsByUTCTime(dateTimeSelected: Date, dateSelected = new Date()): number {
    if (!dateSelected || !dateTimeSelected) {
      return 0;
    }

    const uctDate = new ExtendedDate(
      dateSelected.getFullYear(),
      dateSelected.getMonth(),
      dateSelected.getDate(),
      0,
      0,
      0,
      0
    );

    const calculatedDateTimeSelected = new Date(
      dateSelected.getFullYear(),
      dateSelected.getMonth(),
      dateSelected.getDate(),
      dateTimeSelected.getHours(),
      dateTimeSelected.getMinutes(),
      dateTimeSelected.getSeconds()
    );

    return Math.abs(Math.round((calculatedDateTimeSelected.getTime() - uctDate.getTime()) / 1000));
  }

  isWorkDay(day: number): boolean {
    return day !== 0 && day !== 6;
  }

  getStartDateOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  getEndDateOfMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  getDST(time: Date | string | number | null | undefined): ExtendedDate {
    if (!time) {
      return new ExtendedDate();
    }
    const newTime = (typeof time === 'string') ? new ExtendedDate(time) : time as ExtendedDate;

    if (this.isDSTTime(newTime)) {
      newTime.setHours(newTime.getHours() + 1);
    }

    return newTime;
  }

  // https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
  isDSTTime(dateForTimezone = new ExtendedDate()): boolean {
    const timeZone = this.authFactoryService.service.getSchoolUserAuthority()?.timeZone;
    if (timeZone?.dstL10nDateTimeUtcOffsetSeconds === timeZone?.l10nDateTimeUtcOffsetSeconds) {
      return false;
    }

    const jan = new Date(dateForTimezone.getFullYear(), 0, 1);
    const jul = new Date(dateForTimezone.getFullYear(), 6, 1);
    return dateForTimezone.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  }

  getDateByWorkDayAndOffset(date: ExtendedDate, offset: number, sign: 1 | -1): ExtendedDate {
    let offsetNumber = 0;

    while (offsetNumber !== offset) {
      date.setDate(date.getDate() + sign);

      if (this.isWorkDay(date.getDay())) {
        offsetNumber++;
      }
    }

    return date;
  }

  getDateByCountWeekends(date: ExtendedDate = new ExtendedDate(), shift: 1 | -1 = 1): ExtendedDate {
    const extendedDate = new ExtendedDate(date);

    if (!AppConfig.DateTimePickers.countWeekends) {
      while (!this.isWorkDay(extendedDate.getDay())) {
        extendedDate.setDate(extendedDate.getDate() + shift);
      }

      return extendedDate;
    }

    return extendedDate;
  }

  getDateByCountWeekendsAndSchoolTimeZone(date: ExtendedDate = new ExtendedDate(), shift: 1 | -1 = 1): ExtendedDate {
    const dateBySchoolUtcOffset = this.getDateBySchoolUtcOffset(date);

    if (!AppConfig.DateTimePickers.countWeekends) {
      while (!this.isWorkDay(dateBySchoolUtcOffset.getDay())) {
        dateBySchoolUtcOffset.setDate(dateBySchoolUtcOffset.getDate() + shift);
      }

      return dateBySchoolUtcOffset;
    }

    return dateBySchoolUtcOffset;
  }

  compareDateByDays(firstDate?: Date, secondDate?: Date): 1 | -1 | 0 | null {
    let result: 1 | -1 | 0 | null = null;

    if (!firstDate || !secondDate) {
      return result;
    }

    firstDate.setHours(0, 0, 0, 0);
    secondDate.setHours(0, 0, 0, 0);

    const diffTime = firstDate.getTime() - secondDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 0) {
      result = 1;
    } else if (diffDays < 0) {
      result = -1;
    } else if (diffDays === 0) {
      result = 0;
    }

    return result;
  }

  getMaxDateByConfig(): ExtendedDate {
    const date = new ExtendedDate();
    date.setFullYear(date.getFullYear() + AppConfig.DateManagerService.maxDateYears);

    return date;
  }

  getMinDateByConfig(): ExtendedDate {
    const date = new ExtendedDate();
    date.setFullYear(date.getFullYear() - AppConfig.DateManagerService.maxDateYears);

    return date;
  }

  isDateValid(date: ExtendedDate): boolean {
    return !isNaN(date.getTime());
  }

  convertWeekNumbersToDays(weekNumbers: number[]): string {
    if (!weekNumbers) {
      return '';
    }

    const daysOfTheWeek: string[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'];

    return weekNumbers
      .map(day => {
        return daysOfTheWeek[day];
      })
      .join(',');
  }

  isBeforeToday(date: ExtendedDate | string | Date): boolean {
    return this.compareDateByDays(new ExtendedDate(date), new ExtendedDate()) === -1;
  }

  isAfterToday(date: ExtendedDate | string | Date): boolean {
    return this.compareDateByDays(new ExtendedDate(date), new ExtendedDate()) === 1;
  }

  isEqualToToday(date: ExtendedDate | string | Date | undefined): boolean {
    return this.compareDateByDays(new ExtendedDate(date), new ExtendedDate()) === 0;
  }

  isDatesEqual(
    date: ExtendedDate | string | Date | undefined,
    secondDate: ExtendedDate | string | Date | undefined
  ): boolean {
    return this.compareDateByDays(new ExtendedDate(date), new ExtendedDate(secondDate)) === 0;
  }

  public setStartOfDay(date: Date): void {
    date.setHours(0, 0, 0, 0);
  }

  public setEndOfDay(date: Date): void {
    date.setHours(23, 59, 59, 999);
  }
}
