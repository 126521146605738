<form method="post" enctype="multipart/form-data">
  <div *ngIf="isPrefixMisMatch" class="error-text">
    {{
      'PREFIX_MISMATCH_MESSAGE'
        | translate
          : {
            filePrefix: filePrefix
            }
    }}
  </div>
  <label class="file-uploader" for="file">
    <i class="icon ftmi-file text-primary"></i>
    <label class="title" [ngClass]="cssClass" for="file"> {{ this.file?.name || 'SELECT_FILE_TO_UPLOAD' | translate }} </label>
    <input id="file" type="file" class="d-none" (change)="onFileChange($event)" />
  </label>
</form>