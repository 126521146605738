import { Directive, Input, ElementRef, HostListener, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input('appTooltip') tooltipTitle = '';
  @Input() placement = 'top';
  @Input() delay = 100;
  tooltip?: HTMLElement;
  offset = 10;

  constructor(private el: ElementRef, private renderer: Renderer2, private router: Router, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.tooltip) {
          this.hide();
        }
      }
    });
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) {
      this.show();
      this.cdRef.detectChanges();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
      this.cdRef.detectChanges();
    }
  }

  @HostListener('click') onMouseClick() {
    if (this.tooltip) {
      this.hide();
      this.cdRef.detectChanges();
    }
  }

  show() {
    this.removeExistingTooltips();
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }
  
  private removeExistingTooltips() {
    const existingTooltips = document.querySelectorAll('.ng-tooltip');
    existingTooltips.forEach(tooltip => {
      this.renderer.removeChild(document.body, tooltip);
    });
  }

  hide() {
    window.setTimeout(() => {
      this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = undefined;
    }, this.delay);
  }

  create() {
    this.tooltip = this.renderer.createElement('span');

    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText(this.tooltipTitle) // textNode
    );

    this.renderer.appendChild(document.body, this.tooltip);
    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  setPosition() {
    const hostPos = this.el.nativeElement?.getBoundingClientRect();

    const tooltipPos = this.tooltip?.getBoundingClientRect();

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top, left;
    if (!tooltipPos) return;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos?.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }

  public getTooltipText(): string {
    const classList = this.el.nativeElement.classList;

    if (classList.contains('status-active')) {
      return 'Active';
    } else if (classList.contains('status-inactive')) {
      return 'Inactive';
    }

    return '';
  }
}

//tooltip="I am tool" placement="left" [delay]="100"
