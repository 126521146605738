import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppRoute } from 'src/app/constants/app.route';
import { EventType } from 'src/app/constants/events-type';
import { Alert } from 'src/app/shared/alert/types/alert';
import { AuthFactoryService } from '../services/auth/auth-factory.service';
import { EventManagerService } from '../services/event-manager/event-manager.service';
import { EventContent } from '../services/event-manager/models/event-content';
import { ErrorResponse } from '../services/http/general/interfaces/error-response';
import { RestErrorStates } from '../services/http/general/records/rest-error-states';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  skipErrorHandlingMap: { endpoint: string; status: HttpStatusCode }[] = [
    { endpoint: '/room-list/csv/', status: HttpStatusCode.NotFound }
  ];

  whiteListEndpointMap: { endpoint: string; status: HttpStatusCode }[] = [
    { endpoint: 'ftm/student-list/csv', status: HttpStatusCode.NotFound },
    { endpoint: 'ftm/roster-list/csv', status: HttpStatusCode.NotFound },
    { endpoint: 'authenticated-user', status: HttpStatusCode.InternalServerError },
    { endpoint: 'activity-priority', status: HttpStatusCode.NotFound }
  ];

  errorListRouteMap: { route: AppRoute; status: HttpStatusCode }[] = [
    { route: AppRoute.QRCode, status: HttpStatusCode.BadRequest },
    { route: AppRoute.QRCode, status: HttpStatusCode.Conflict }
  ];

  notRegisteredEndpointMap: { endpoint: string; status: HttpStatusCode } = {
    endpoint: 'authentication/client-token',
    status: HttpStatusCode.Unauthorized
  };

  noAuthEndpointMap: { route: AppRoute; status: HttpStatusCode }[] = [
    { route: AppRoute.NoAccess, status: HttpStatusCode.Unauthorized }
  ];

  constructor(
    private router: Router,
    private eventManager: EventManagerService,
    private authFactoryService: AuthFactoryService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.hidePreloader();
        const isRequestIncludesNotRegisteredEndpointMap =
          request.url.includes(this.notRegisteredEndpointMap.endpoint) &&
          this.notRegisteredEndpointMap.status === error.status;

        if (isRequestIncludesNotRegisteredEndpointMap) {
          this.redirectNotRegisteredUser();
          return this.createEmptyObservable();
        }

        const targetNoAuthEndpointMap = this.noAuthEndpointMap.find(item => item.status === error.status);

        if (targetNoAuthEndpointMap) {
          this.redirectUnAuthUser(targetNoAuthEndpointMap.route);
          return this.createEmptyObservable();
        }
        if(this.handleFlexPeriodLocked(error)){
          this.flexPeriodLockedAlert();
        }

        if (this.handleErrorListRouteError(this.router, error)) {
          return throwError(error);
        }

        if (this.handleWhiteListEndPointError(request, error)) {
          return this.createEmptyObservable();
        }

        if (this.handleApiError(error)) {
          return this.createEmptyObservable();
        }

        if (this.handlePasswordValidationApiError(error)) {
          return throwError(null);
        }

        if (!this.skipHandlingApiError(request, error) && !this.handleFlexPeriodLocked(error)) {
          this.handleNotHandledApiError(error);
        }

        return throwError(error);
      })
    );
  }

  private flexPeriodLockedAlert(): void {
    const alert: Alert = {
      type: 'danger',
      dismissOnTimeout: 0,
      translateKey: "FLEX_PERIOD_LOCKED",
    };
    this.eventManager.broadcast(new EventContent(EventType.AlertMessage, alert));
  }

  private redirectNotRegisteredUser(): void {
    this.router.navigate([AppRoute.Login], { queryParams: { errorMessage: 'USER_NOT_REGISTERED' } });
  }

  private redirectUnAuthUser(route: AppRoute): void {
    this.authFactoryService.service.clear();
    this.router.navigateByUrl(route);
  }

  private hidePreloader(): void {
    this.eventManager.broadcast(new EventContent(EventType.PreloaderShow, false));
  }

  private handleApiError(error: HttpErrorResponse): boolean {
    if (!error.error.code) {
      return false;
    }

    const alert: Alert = {
      type: 'danger',
      dismissOnTimeout: 0,
      translateKey: error.error.code,
      messageDetail: ''
    };

    if (Object.prototype.hasOwnProperty.call(error.error, 'additional')) {
      if (!Array.isArray(error.error.additional)) {
        error.error.additional = [error.error.additional];
      }
      alert.messageDetail = error.error.additional
        .map((errorObject: any) =>
          Object.keys(errorObject)
            .map(key => `${key} - ${errorObject[key]}`)
            .join(', ')
        )
        .join('\n');
    }
    this.eventManager.broadcast(new EventContent(EventType.AlertMessage, alert));

    return true;
  }

  private skipHandlingApiError(request: HttpRequest<any>, error: HttpErrorResponse): boolean {
    return (
      this.skipErrorHandlingMap.find(item => request.url.includes(item.endpoint) && item.status === error.status) !==
      undefined
    );
  }

  private handleNotHandledApiError(error: HttpErrorResponse): void {
    const alert: Alert = {
      type: 'danger',
      dismissOnTimeout: 0,
      translateKey: 'GENERAL_ERROR'
    };

    if (RestErrorStates[error.status]) {
      alert.translateKey = RestErrorStates[error.status];
      const errorResponse = error.error as ErrorResponse;
      alert.interpolateParams = { value: errorResponse?.message };
    }

    this.eventManager.broadcast(new EventContent(EventType.AlertMessage, alert));
  }

  private handleWhiteListEndPointError(request: HttpRequest<any>, error: HttpErrorResponse): boolean {
    return (
      this.whiteListEndpointMap.find(item => request.url.includes(item.endpoint) && item.status === error.status) !==
      undefined
    );
  }

  private handleErrorListRouteError(router: Router, error: HttpErrorResponse): boolean {
    return (
      this.errorListRouteMap.find(item => router.url.includes(item.route) && item.status === error.status) !== undefined
    );
  }

  private handlePasswordValidationApiError(error: HttpErrorResponse): boolean {
    if (!error.error.details) {
      return false;
    }

    let translateKey = '';
    let errorDetails = error.error.details;

    if (Object.prototype.hasOwnProperty.call(errorDetails, 'user') && !Object.prototype.hasOwnProperty.call(errorDetails, 'password')) {
      if (Array.isArray(error.error.details['user'])) {
        const match = 'Invalid array input. Errors: ';
        error.error.details['user'].forEach(msg => {
            if (msg.startsWith(match)) {
              errorDetails = JSON.parse(msg.substring(match.length + 1, msg.length - 2));
            }
        });
      }
    }

    if (Object.prototype.hasOwnProperty.call(errorDetails, 'password')) {
      switch (Object.keys(errorDetails['password'])[0]) {
        case 'min-length':
          translateKey = 'EM_PASSWORD_MIN_LENGTH';
          break;
        case 'max-length':
          translateKey = 'EM_PASSWORD_MAX_LENGTH';
          break;
        case 'allowed-chars':
          translateKey = 'EM_PASSWORD_NOT_ALLOWED_CHARACTERS';
          break;
        case 'weak-complexity':
          translateKey = 'EM_PASSWORD_WEAK_COMPLEXITY';
          break;
      }
    }

    if (translateKey === '') {
      return false;
    }

    const alert: Alert = {
      type: 'danger',
      dismissOnTimeout: 0,
      translateKey
    };

    this.eventManager.broadcast(new EventContent(EventType.AlertMessage, alert));

    return true;
  }

  private createEmptyObservable(): Observable<any> {
    return new Observable(observer => {
      observer.next(), observer.complete();
    });
  }

  private handleFlexPeriodLocked(error: HttpErrorResponse): boolean {
    const flexPeriodLocked = error.error?.flexPeriod?.flexPeriodLocked || error.error?.details?.flexPeriod?.flexPeriodLocked;
    return !!flexPeriodLocked; 
  }
}
