import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ClassInputType } from '../types/class-input-type';

@Component({
  selector: 'app-source-type-select',
  templateUrl: './source-type-select.component.html',
  styleUrls: ['./source-type-select.component.scss']
})
export class SourceTypeSelectComponent {
  @Input() controlName = 'manuallyAdded';
  @Input() form: UntypedFormGroup | undefined;
  @Input() className: ClassInputType = 'app-input';
}
